import { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import env from '../../env';
import { Inbox, Notifications } from '@novu/react';
import { Icon, Lightbox } from '../../design-system';
import './index.css';

const Novu = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>('');
  const [open, setOpen] = useState(false);
  const getUserData = async () => {
    const { data: sessionData } = await supabase.auth.getSession();
    const user = sessionData?.session?.user;
    setUserId(user?.id || '');
  };

  useEffect(() => {
    getUserData();
  }, []);

  function handleOnNotificationClick(message: any) {
    if (message.redirect.url) {
      navigate(message.redirect.url);
    }
  }
  if (userId) {
    return (
      <>
        <Lightbox
          visible={open}
          closeActtion={() => setOpen(false)}
          calcSnapPoints={({ maxHeight }) => [maxHeight * 0.9, maxHeight * 0.6]}
        >
          <div className="relative">
            <Inbox
              subscriberId={userId}
              applicationIdentifier={env.APP_NOVU_IDENTIFIER || ""}
            >
              <Notifications onNotificationClick={handleOnNotificationClick} />
            </Inbox>
          </div>
        </Lightbox>
        <button
          title={'notification'}
          onClick={() => setOpen(true)}
          className="md:hidden"
        >
          <Icon width={30} height={30} name={'Bell'} color="#828299" />
        </button>
        <div className='md:block hidden'>
          <Inbox
            subscriberId={userId}
            applicationIdentifier={env.APP_NOVU_IDENTIFIER || ""}
            renderBell={(unreadCount) => {
              return (
                <div className='relative'>
                  {!!unreadCount && (
                    <span className="rounded-full w-spacing-8 h-spacing-8 bg-red-500 block absolute"></span>
                  )}
                  <Icon width={30} height={30} name={'Bell'} color="#828299" />
                </div>
              );
            }}
            onNotificationClick={handleOnNotificationClick}
            routerPush={(path: string) => {console.log(path, "PATH");
            }}
          ></Inbox>
        </div>
      </>
    );
  }

  return <></>;
};

export default Novu;
