import { useEffect, lazy, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as ListRoutes,
  Navigate,
} from 'react-router-dom';
import AppLayout from './components/layouts/AppLayout';
import Protected from './components/ProtectedRoute';
import SuspenseLoading from './components/suspenseLoading';
import AppGoBack from './components/layouts/AppGoBack';
import getUrlParameters from './utils/getUrlParameters';
import NoMatch from './screens/NoMatch';
import PartnerAdvice from './screens/PartnerScreens/Advice';
import CheckSession from './components/CheckSession';
import GAPageView from './components/GAPageView';
import CheckRole from './components/CheckRole';
import Loading from './components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { setRole } from './reducers/adviceReducer';
import { IRootState } from './store';

/**
 * TODO: home
 * enable to see the home screen
 */
// const Home = lazy(() => import('./screens/AppScreens/Home'));
const HomeMilestone3 = lazy(
  () => import('./screens/AppScreens/HomeMilestone3')
);
const Login = lazy(() => import('./screens/AppScreens/Authentication/Login'));
const Consultation = lazy(() => import('./screens/AppScreens/Consultation'));
const ConsultationSuccess = lazy(
  () => import('./screens/AppScreens/ConsultationSuccess')
);
const Register = lazy(
  () => import('./screens/AppScreens/Authentication/Register')
);
const ForgotPassword = lazy(
  () => import('./screens/AppScreens/Authentication/ForgotPassword')
);
const IsMobile = lazy(() => import('./screens/AppScreens/IsMobile'));
const ResetPassword = lazy(
  () => import('./screens/AppScreens/Authentication/ResetPassword')
);
const Checkout = lazy(
  () => import('./screens/AppScreens/SubmitPayment/Checkout')
);

const Payment = lazy(() => import('./screens/AppScreens/Payment'));

const PaymentRefresh = lazy(
  () => import('./screens/AppScreens/PaymentRefresh')
);

const PaymentSuccess = lazy(
  () => import('./screens/AppScreens/PaymentSuccess')
);

const PaymentSummary = lazy(
  () => import('./screens/AppScreens/SubmitPayment/Summary')
);

const AdvisorAdviceDetails = lazy(
  () => import('./screens/PartnerScreens/AdviceDetails')
);

const AdvisorPayments = lazy(() => import('./screens/PartnerScreens/Payments'));

const PartnerForm = lazy(() => import('./screens/PartnerScreens/PartnerInfo'));

const ProposalDetails = lazy(
  () =>
    import(
      './screens/AppScreens/AdviceDetails/tabs/Proposals/screens/ProposalDetails'
    )
);

const ProposalPayment = lazy(
  () =>
    import(
      './screens/AppScreens/AdviceDetails/tabs/Proposals/screens/ProposalPayment'
    )
);

const CreateProposal = lazy(
  () =>
    import(
      './screens/PartnerScreens/AdviceDetails/tabs/Proposals/screens/CreateProposal'
    )
);

const AdviceAnswers = lazy(() => import('./screens/AppScreens/AdviceAnswers'));

const Support = lazy(() => import('./screens/AppScreens/Support'));

const QuestionsFlow = lazy(() => import('./screens/QuestionsFlow'));

const Routes = () => {
  const { role } = useSelector((state: IRootState) => state.advice);

  const dispatch = useDispatch();

  const setRoleState = (role: string) => {
    dispatch(setRole(role));
  };

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const aid = getUrlParameters('aid');

    if (aid) {
      localStorage.setItem('aid', aid);
    }

    setLoading(false);
  }, []);

  const Routes = () => {
    return (
      <ListRoutes>
        <Route
          path="/reset_password"
          element={
            <SuspenseLoading variant="FULL">
              <ResetPassword />
            </SuspenseLoading>
          }
        />
        <Route element={<GAPageView />}>
          <Route element={<CheckSession />}>
            <Route
              path="/register"
              element={
                <SuspenseLoading variant="FULL">
                  <Register />
                </SuspenseLoading>
              }
            />
            <Route
              path="/login"
              element={
                <SuspenseLoading variant="FULL">
                  <Login />
                </SuspenseLoading>
              }
            />
            <Route
              path="/login/registered"
              element={
                <SuspenseLoading variant="FULL">
                  <Login />
                </SuspenseLoading>
              }
            />
            <Route
              path="/forgot_password"
              element={
                <SuspenseLoading variant="FULL">
                  <ForgotPassword />
                </SuspenseLoading>
              }
            />
            <Route
              path="/consult/login"
              element={
                <SuspenseLoading variant="FULL">
                  <Login />
                </SuspenseLoading>
              }
            />
            <Route
              path="/consult/register"
              element={
                <SuspenseLoading variant="FULL">
                  <Register />
                </SuspenseLoading>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <SuspenseLoading variant="FULL">
                <NoMatch />
              </SuspenseLoading>
            }
          />
          <Route
            path="/advice/answers/:adviceId"
            element={
              <SuspenseLoading variant="FULL">
                <AdviceAnswers />
              </SuspenseLoading>
            }
          />
          <Route
            path="/stripeconnect/success"
            element={
              <SuspenseLoading variant="FULL">
                <PaymentSuccess />
              </SuspenseLoading>
            }
          />
          <Route element={<AppGoBack />}>
            <Route
              path="/ismobile"
              element={
                <SuspenseLoading variant="FULL">
                  <IsMobile />
                </SuspenseLoading>
              }
            />
          </Route>
          <Route element={<Protected role={role} setRole={setRoleState} />}>
            <Route
              path="/consult"
              element={
                <SuspenseLoading variant="FULL">
                  <Consultation />
                </SuspenseLoading>
              }
            />
            <Route
              path="/consult/success"
              element={
                <SuspenseLoading variant="FULL">
                  <ConsultationSuccess />
                </SuspenseLoading>
              }
            />
            <Route
              path="/support"
              element={
                <SuspenseLoading variant="FULL">
                  <Support />
                </SuspenseLoading>
              }
            />
            <Route
              path="/support/:id"
              element={
                <SuspenseLoading variant="FULL">
                  <Support />
                </SuspenseLoading>
              }
            />
            <Route element={<AppLayout />}>
              <Route
                element={<CheckRole currentRole={role} roleToCheck="client" />}
              >
                <Route
                  path="/advice"
                  element={
                    <SuspenseLoading variant="FULL">
                      <HomeMilestone3 />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/advice/:tab"
                  element={
                    <SuspenseLoading variant="FULL">
                      <HomeMilestone3 />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/questionnaire"
                  element={
                    <SuspenseLoading variant="FULL">
                      <QuestionsFlow />
                    </SuspenseLoading>
                  }
                />
              </Route>
              <Route
                path="/"
                element={
                  <SuspenseLoading variant="FULL">
                    {role === 'advisor' ? (
                      <Navigate to="/partner/advice" replace />
                    ) : (
                      <Navigate to="/advice" replace />
                    )}
                  </SuspenseLoading>
                }
              />
            </Route>
            <Route element={<AppLayout short />}>
              <Route
                path="/advice/proposal/:adviceId/:proposalId"
                element={
                  <SuspenseLoading variant="FULL">
                    <ProposalDetails />
                  </SuspenseLoading>
                }
              />
              <Route
                path="/advice/proposal/:adviceId/:proposalId/:status"
                element={
                  <SuspenseLoading variant="FULL">
                    <ProposalDetails />
                  </SuspenseLoading>
                }
              />
              <Route
                path="/proposal/payment/:adviceId/:proposalId/:type"
                element={
                  <SuspenseLoading variant="FULL">
                    <ProposalPayment />
                  </SuspenseLoading>
                }
              />
            </Route>
            <Route element={<AppGoBack />}>
              <Route
                path="/stripeconnect"
                element={
                  <SuspenseLoading variant="FULL">
                    <Payment />
                  </SuspenseLoading>
                }
              />
              <Route
                path="/stripeconnect/refresh"
                element={
                  <SuspenseLoading variant="FULL">
                    <PaymentRefresh />
                  </SuspenseLoading>
                }
              />
              <Route
                path="/payment/checkout"
                element={
                  <SuspenseLoading variant="APP">
                    <Checkout />
                  </SuspenseLoading>
                }
              />
              <Route
                path="/payment/summary"
                element={
                  <SuspenseLoading variant="FULL">
                    <PaymentSummary />
                  </SuspenseLoading>
                }
              />
            </Route>
            <Route
              element={<CheckRole currentRole={role} roleToCheck="advisor" />}
            >
              <Route element={<AppLayout />}>
                <Route
                  path="/partner/advice"
                  element={
                    <SuspenseLoading variant="FULL">
                      <PartnerAdvice />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/partner/info"
                  element={
                    <SuspenseLoading variant="FULL">
                      <PartnerForm />
                    </SuspenseLoading>
                  }
                />
              </Route>
              <Route element={<AppLayout short />}>
                <Route
                  path="/partner/advice/:adviceId"
                  element={
                    <SuspenseLoading variant="FULL">
                      <AdvisorAdviceDetails />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/partner/advice/:adviceId/:tab"
                  element={
                    <SuspenseLoading variant="FULL">
                      <AdvisorAdviceDetails />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/partner/payments"
                  element={
                    <SuspenseLoading variant="FULL">
                      <AdvisorPayments />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/proposal/create/:adviceId/:proposalId"
                  element={
                    <SuspenseLoading variant="FULL">
                      <CreateProposal />
                    </SuspenseLoading>
                  }
                />
                <Route
                  path="/partner/proposal/templates"
                  element={
                    <SuspenseLoading variant="FULL">
                      <CreateProposal />
                    </SuspenseLoading>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </ListRoutes>
    );
  };

  if (loading) {
    return <Loading variant={'FULL'} />;
  }

  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default Routes;
